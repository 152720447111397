import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

let serviceURL = process.env.REACT_APP_API_URL;
console.log('service URL is ' + serviceURL);

const root = ReactDOM.createRoot(document.getElementById('root'),  { debug: true });
root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>    
        </React.StrictMode>
);
