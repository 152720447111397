import React from "react";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import Heading from "../components/Heading";

function RegisteredPage() {
  return (
    <>
      <PageHeader></PageHeader>
      <PageBody>
          <Heading text="Account Registered"/>
          <p>Your new account has been registered. You should receive an email shortly with a verification link.</p>
      </PageBody>
    </>
  );
}

export default RegisteredPage;
