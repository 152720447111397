import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import Heading from "../components/Heading";
import UserDashboard from "../components/UserDashboard";

const DashboardPage = () => {
    return (
      <>
        <PageHeader></PageHeader>
        <PageBody>
          <Heading text="Happy Brain Science Account"/>
          <UserDashboard/>
        </PageBody>
      </>
    );
}

export default DashboardPage;
