import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import Heading from "../components/Heading";

const HomePage = () => {
    return (
      <>
        <PageHeader></PageHeader>
        <PageBody>
          <Heading text="Account Management"/>
          <p>
            Welcome to Happy Brain Science account management. You can register a new account or login to your existing account.
          </p>
          <div className="flex p-1">
            <Link to="/login">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
                type="button"
              >
                Login
              </button>
            </Link>
            <Link to="/register">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mx-1"
                type="button"
              >
                Register
              </button>
            </Link>
          </div>
        </PageBody>
      </>
    );
}

export default HomePage;
