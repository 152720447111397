import PageHeader from '../components/PageHeader';
import PageBody from '../components/PageBody';
import Heading from "../components/Heading";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PasswordUpdatePage() {
  const { passwordUpdateId } = useParams();
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/resources/account/update/${passwordUpdateId}`)
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => console.error(error));
  }, [passwordUpdateId]);

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const password = form.password.value;
    const confirmPassword = form.confirmPassword.value;
    
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    
    const requestBody = {
      passwordUpdateId: passwordUpdateId,
      password: password
    };
      
    fetch(process.env.REACT_APP_API_URL + '/resources/account/passwordUpdate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          navigate('/login');          
        } else {
          alert("Failed to update password.");
        }
      })
      .catch(error => {
        console.error(error);
        alert("Failed to updated password.");
      });
  }
  
  if (!userData) {
    return <div>Loading...</div>;
  }

  return (    
    <>    
      <PageHeader></PageHeader>      
      <PageBody>
        <Heading text="Update Password"/>
        <p>Please update your password:</p>
        <div className="flex flex-col items-center">          
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <p className="mb-4"><span className="font-bold">First Name:</span> {userData.firstName}</p>
            <p className="mb-4"><span className="font-bold">Last Name:</span> {userData.lastName}</p>
            <p className="mb-4"><span className="font-bold">Location:</span> {userData.location}</p>
            <p className="mb-4"><span className="font-bold">Email:</span> {userData.email}</p>
            <form onSubmit={handleSubmit} className="mb-4">
              <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Password:</label>
              <input type="password" id="password" name="password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              <label htmlFor="confirmPassword" className="block text-gray-700 font-bold mb-2 mt-4">Confirm Password:</label>
              <input type="password" id="confirmPassword" name="confirmPassword" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline">Update Password</button>
            </form>
          </div>
        </div>
        </PageBody>
    </>
  );
}

export default PasswordUpdatePage;
