import React, { Component } from "react";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loginFailure: undefined });
    let request = {
      emailAddress: this.state.email,
      password: this.state.password,
    };
    fetch(process.env.REACT_APP_API_URL + "/resources/account/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((res) => res.json())
      .then((loginResponse) => {
        console.log("login response", loginResponse);
        if (loginResponse.success) {
          console.log("success", loginResponse.accessToken);
          // Set the access token as a cookie that expires in 24 hours
          const expirationDate = new Date(new Date().getTime() + 60 * 60 * 1000 * 24); // 24 hours
          document.cookie = `happybrainscience_auth=${loginResponse.accessToken}; expires=${expirationDate.toUTCString()}`;          
          if (this.props.onSuccess) {
            this.props.onSuccess(loginResponse);
          }
        } else {
          this.setState({ loginFailure: loginResponse.errorMessage });
          console.log("login failure", loginResponse.errorMessage);
        }
      });
  };

  handleForgotPassword = (event) => {
    event.preventDefault();
    // Call the onForgotPassword callback passed in as a prop
    if (this.props.onForgotPassword) {
      this.props.onForgotPassword();
    }
  };

  render() {
    return (        
      <form onSubmit={this.handleSubmit} className="max-w-md mx-auto mt-8 p-3">
        <div className="w-full py-2">
            <p className="text-red-500">{this.state.loginFailure}</p>
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block mb-2 font-bold text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
            className="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block mb-2 font-bold text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
            className="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login
          </button>
          <a
            href="/"
            onClick={this.handleForgotPassword}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            Forgot Password?
          </a>
        </div>        

      </form>
    );
  }
}

export default LoginForm;
