import PageHeader from "../components/PageHeader";
import Heading from "../components/Heading";
import PageBody from "../components/PageBody";
import LoginForm from "../components/LoginForm";
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader></PageHeader>
      <PageBody>
        <Heading text="Login" />
        <LoginForm 
          onSuccess={() => {
            console.log('login success');
            navigate('/dashboard');
          }}      
          onForgotPassword={() => {
            console.log('forgot password');
            navigate('/forgotPassword');
          }}></LoginForm>
      </PageBody>
    </>
  );
};

export default LoginPage;
