import React, { useEffect, useState } from 'react';
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import RegisterForm from "../components/RegisterForm";
import Heading from "../components/Heading";
import { useLocation, useNavigate } from 'react-router-dom';

function RegisterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const registrationId = queryParams.get('id');
    if (registrationId) {
      console.log('URL parameter id=' + registrationId);
      // Fetch data from the service
      fetchData(registrationId);
    }
  }, [location]);

  const fetchData = (registrationId) => {
    // Example fetch request
    fetch(process.env.REACT_APP_API_URL + `/resources/account/registration/${registrationId}`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming the service returns an object with firstName, lastName, email, etc.
        let initialData = {
          emailAddress: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          location: data.location,
        }
        setInitialData(initialData);
        console.log('set initial data', initialData);
        console.log("submit registration", this.state);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const registerSuccess = (regResponse) => {
    console.log('registration success', regResponse);
    navigate('/registered');
  };

  return (
    <>
      <PageHeader />
      <PageBody>
        <Heading text="Register User" />
        <RegisterForm onSuccess={registerSuccess} initialData={initialData} />
      </PageBody>
    </>
  );
}

export default RegisterPage;

