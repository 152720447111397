import React, { Component } from "react";

class RegisterForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailAddress: "",
      firstName: "",
      lastName: "",
      location: "",
      ...props.initialData, // Spread the initialData into the state if it exists
    };
  }

  registrationSubmit() {
    console.log("submit registration", this.state);
    this.setState({ registrationFailure: undefined });
    let request = {
      emailAddress: this.state.emailAddress,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      location: this.state.location,
    };
    fetch(process.env.REACT_APP_API_URL + "/resources/account/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((res) => res.json())
      .then((regResponse) => {
        console.log("register response", regResponse);
        if (regResponse.success) {  
          console.log("sucsess", regResponse.registrationId);        
          if(this.props.onSuccess) {            
            this.props.onSuccess(regResponse);
          }
        } else {
          this.setState({ registrationFailure: regResponse.errorMessage });
          console.log("registration failure", regResponse.errorMessage);
        }
      });
  }

  handleEmailAddressChange(event) {
    this.setState({ emailAddress: event.target.value });
  }

  handleFirstNameChange(event) {
    this.setState({ firstName: event.target.value });
  }

  handleLastNameChange(event) {
    this.setState({ lastName: event.target.value });
  }

  handleLocationChange(event) {
    this.setState({ location: event.target.value });
  }

  render() {
    return (
      <form className="w-full max-w-lg p-3">
        <div className="flex flex-wrap mb-6">
          <div className="w-full py-2">
            <p className="text-red-500">{this.state.registrationFailure}</p>
          </div>
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-emailaddress"
            >
              Email Address
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-emailaddress"
              type="text"
              value={this.state.emailAddress}
              onChange={this.handleEmailAddressChange.bind(this)}
              placeholder="Your email address"
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Your first name"
              value={this.state.firstName}
              onChange={this.handleFirstNameChange.bind(this)}
            />
            {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Your last name"
              value={this.state.lastName}
              onChange={this.handleLastNameChange.bind(this)}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-location"
            >
              Location
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-location"
              type="text"
              value={this.state.location}
              onChange={this.handleLocationChange.bind(this)}
              placeholder="City & country of residence"
            />
          </div>
        </div>
        <div className="flex p-3">
          <p>
            Submitting this form will send an email to the address you've
            registered. The email will contain a link that you click to go to a
            page to enter a new password. After that you can login to your
            account.
          </p>
        </div>
        <div className="flex p-3">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 p-2 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={this.registrationSubmit.bind(this)}
          >
            Register New User
          </button>
        </div>
      </form>
    );
  }
}

export default RegisterForm;
