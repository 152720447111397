import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage"
import LoginPage from "./pages/LoginPage"
import ForgotPasswordPage from "./pages/ForgotPasswordPage"
import RegisterPage from "./pages/RegisterPage"
import RegisteredPage from "./pages/RegisteredPage"
import AccountCreatedPage from "./pages/AccountCreatedPage"
import VerifyUserPage from "./pages/VerifyUserPage"
import DashboardPage from "./pages/DashboardPage"
import PasswordUpdatePage from "./pages/PasswordUpdatePage"

function App() {
  return (
    <Routes baseName="/">
      <Route path= "/" element={<HomePage/>}></Route>
      <Route path="/login" element={<LoginPage/>}></Route>
      <Route path="/dashboard" element={<DashboardPage/>}></Route>
      <Route path="/register" element={<RegisterPage/>}></Route>
      <Route path="/registered" element={<RegisteredPage/>}></Route>
      <Route path="/created" element={<AccountCreatedPage/>}></Route>
      <Route path="/forgotPassword" element={<ForgotPasswordPage/>}></Route>
      <Route path="/verify/:verificationId" element={<VerifyUserPage/>}></Route>
      <Route path="/passwordUpdate/:passwordUpdateId" element={<PasswordUpdatePage/>}></Route>
    </Routes>
  );
}

export default App;
