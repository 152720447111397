import React from "react";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import Heading from "../components/Heading";

function AccountCreatedPage() {
  return (
    <>
      <PageHeader></PageHeader>
      <PageBody>
          <Heading text="Account Created"/>
          <p>Your new account has been created.</p>
          <p>You should now be able to <a href='/login'>login</a> with your username and password.</p>
      </PageBody>
    </>
  );
}

export default AccountCreatedPage;
