import React, { useState, useEffect } from "react";
import { getCookie } from "../utils/cookieService";

const UserDashboard = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const sessionId = getCookie("happybrainscience_auth");
      if (sessionId) {
        try {
          const authorizeResponse = await fetch(
            `/resources/account/authorize/${sessionId}`
          );
          
          if (authorizeResponse.status === 401) {
            // Redirect to home page if unauthorized
            window.location.href = '/';
            return; 
          }
          
          const sessionData = await authorizeResponse.json();
          // Assuming sessionData contains userId, adjust as necessary
          const userDetailsResponse = await fetch(
            `/resources/account/user/${sessionData.userId}`
          );
          const userDetails = await userDetailsResponse.json();
          setUserData(userDetails);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchData();
  }, []);

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-bold mb-4">
        {userData.firstName} {userData.lastName}
      </h1>
      <table className="w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Product Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Product URL
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Purchase Date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {userData.products.map((product) => (             
            <tr key={product.productId}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {product.productName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <a
                  href={product.productURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {product.productURL}
                </a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(product.purchaseDate).toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserDashboard;
