import React from "react";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import Heading from "../components/Heading";

import { useNavigate } from 'react-router-dom';

function ForgotPasswordPage() {

  const navigate = useNavigate();

  function forgotPasswordSuccess(regResponse) {  
    navigate('/');
  }

  return (
    <>
      <PageHeader></PageHeader>
      <PageBody>
        <Heading text="Forgot Password"/>
        <ForgotPasswordForm onSuccess={forgotPasswordSuccess}></ForgotPasswordForm>
      </PageBody>
    </>
  );
}

export default ForgotPasswordPage;
