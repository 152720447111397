import React, { Component } from "react";

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: ""
    };
  }

  forgotPasswordSubmit() {
    console.log("submit forgot password", this.state);
    this.setState({ forgotPasswordFailure: undefined });
    let request = {
      emailAddress: this.state.emailAddress
    };
    fetch(process.env.REACT_APP_API_URL + "/resources/account/passwordUpdateRequest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((res) => res.json())
      .then((forgotPasswordResponse) => {
        console.log("forgot password response", forgotPasswordResponse);
        if (forgotPasswordResponse.success) {  
          console.log("success", forgotPasswordResponse.registrationId);        
          if(this.props.onSuccess) {            
            this.props.onSuccess(forgotPasswordResponse);
          }
        } else {
          this.setState({ forgotPasswordFailure: forgotPasswordResponse.errorMessage });
          console.log("forgot password failure", forgotPasswordResponse.errorMessage);
        }
      });
  }

  handleEmailAddressChange(event) {
    this.setState({ emailAddress: event.target.value });
  }

  render() {
    return (
      <form className="w-full max-w-lg p-3">
        <div className="flex flex-wrap mb-6">
          <div className="w-full px-3">
            <p className="text-red-500">{this.state.forgotPasswordFailure}</p>
          </div>
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-emailaddress"
            >
              Email Address
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-emailaddress"
              type="text"
              value={this.state.emailAddress}
              onChange={this.handleEmailAddressChange.bind(this)}
              placeholder="Your email address"
            />
          </div>
        </div>
        <div className="flex p-3">
          <p>
            Submitting this form will send an email to the registered address you've
            provided. The email will contain a link that you'll click to go to a
            page to enter a new password. 
          </p>
        </div>
        <div className="flex p-3">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 p-2 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={this.forgotPasswordSubmit.bind(this)}
          >
            Change Password Request
          </button>
        </div>
      </form>
    );
  }
}

export default RegisterForm;
